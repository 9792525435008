import React from 'react';
import axios from 'axios';
import { reducer } from './reducer';
import { getText, getSquads } from '../helpers';

const StateContext = React.createContext({});
const StateDispatchContext = React.createContext(() => null);
const initialState = {
  showStartModal: true,
  showShareModal: false,
  text: {},
  match: {},
  teams: [],
  selectedTeam: {},
  opponent: {},
  date: null,
  stadium: null,
  stage: null,
  activePosition: null,
  activeFormation: [4,4,2],
  formation: [
    [null],
    [null, null, null, null],
    [null, null, null, null],
    [null, null]
  ],
  players: [] 
};

export const useStateContext = () => {
  const context = React.useContext(StateContext)
  if (typeof context === undefined) {
    throw new Error('Cannot use `useStateContext` outside a StateProvider')
  }
  return context
}

export const useStateDispatchContext = () => {
  const context = React.useContext(StateDispatchContext)
  if (typeof context === undefined) {
    throw new Error('Cannot use `useStateDispatchContext` outside a StateProvider')
  }
  return context
}

export const StateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const fetchTeams = async () => {

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let matchId = params.matchId;
    let locale = params.locale ? params.locale.split('-')[0] : "EN";
    
    axios.get(`https://api.fifa.com/api/v3/live/football/${matchId}`).then(res => {
      
      getSquads([res.data.HomeTeam.IdTeam, res.data.AwayTeam.IdTeam], res.data.IdSeason, res.data.IdCompetition)
      .then(response => {
        dispatch({ 
          type: 'SET_SQUADS',
          squads: response
        })
      })

      dispatch({ 
        type: 'SET_MATCH',
        date: res.data.Date, 
        stadium: `${res.data.Stadium.Name[0].Description}, ${res.data.Stadium.CityName[0].Description}`, 
        stage: res.data.StageName[0].Description,
        text: getText(locale.toUpperCase()) 
      })
    })
  };

  const setTeam = async (index) => {
    let opponent;

    if(index === 1) {
      opponent = state.teams[0];
    }
    else {
      opponent = state.teams[1]
    }
    dispatch({ type: 'SET_TEAM', team: state.teams[index], opponent: opponent, players: state.teams[index].Players  })
  };

  const dispatchState = { dispatch, setTeam, fetchTeams };

  return (
    <StateContext.Provider value={state}>
      <StateDispatchContext.Provider value={dispatchState}>
        {children}
      </StateDispatchContext.Provider>
    </StateContext.Provider>
  )
}