import styled from 'styled-components';

export const ModalDiv = styled.div`
  position: fixed;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: visibility 0.5s, opacity 0.5s linear;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-image: linear-gradient(120deg, #550065, rgba(0,0,0,0.5));

  section {
    width: 100%;
    height: 75%;
    position: fixed;
    bottom: 0;
    background: #fefefe;
    border-radius: 2.5rem 2.5rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 2rem;

    h2 {
      font-family: QatarBold;
      margin-bottom: 0.5rem;
      width: 90%;
      text-align: center;
    }

    p {
      width: 90%;
      margin: 0 auto;
      text-align: center;
    }

    ul {
      padding: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;

      li {
        margin: 0 0.5rem;
        padding: 0;
        
        figure {
          width: 100%;
          height: 100%;
          margin: 0;

          img {
            height: 3rem;
          }
        }
      }
    }

    img {
      height: 14rem;
    }
  }

  .logo {
    text-align: center;
    padding: 1.5rem 0;
    border-top: solid 2px #550065;

    img {
      height: 10rem;
    }
  }
`;

export const ShareButton = styled.button`
  position: relative;
  display: block;
  background: rgba(10,10,10,0.15);
  color: #550065;
  border: none;
  width: 80%;
  padding: 1rem;
  border-radius: 2rem;
  margin: 1rem auto;
  cursor: pointer;
  font-family: QatarRegular;
  font-size: 1.4rem;

  &:disabled {
    background: rgba(85, 0, 101, 0.1);
    cursor: default;
  }

  &:not(&:disabled) {
    &:hover {
      background: #cccccc;
    }
  }
`;
