export const localeText = 
  [
    {
      "title": "appTitle", 
      "text": 
        {
          "EN": "Play our World Cup line up predictor!",
          "DE": "Startelf tippen!",
          "FR": "Découvrez notre jeu de composition des équipes pour la Coupe du Monde !",
          "ES": "Juega al pronosticador de alineaciones.",
          "AR": "شارك في لعبة توقع التشكيلة الأساسية في كأس العالم!",
          "PT": "Jogue o bolão da escalação da Copa do Mundo!",
          "IT": "Gioca con il nostro pronostico per la formazione della Coppa del Mondo!"
        }
    },
    {
      "title": "lineupHeader", 
      "text": 
      {
        "EN": "What do you believe is the winning line up?",
        "DE": "Wie lautet die siegreiche Startelf?",
        "FR": "Quelle composition d’équipe va l’emporter ?",
        "ES": "¿Qué jugadores elegirá el seleccionador?",
        "AR": "ما هي برأيك التشكيلة الفائزة؟",
        "PT": "Qual você acredita ser a escalação vencedora?",
        "IT": "Quale credi sia la formazione vincente?"
      }
    },
    {
      "title": "shareText", 
      "text": {
        "EN": "Share",
        "DE": "Teilen",
        "FR": "Partager",
        "ES": "Compartir",
        "AR": "شارك",
        "PT": "Compartilhar",
        "IT": "Condividere"
      }
    },
    {
      "title": "screenshotTitle", 
      "text":  {
        "EN": "My starting 11",
        "DE": "Meine Startelf",
        "FR": "Mon onze de départ",
        "ES": "Mi once titular",
        "AR": "تشكيلتي الأساسية",
        "PT": "Meus 11 titulares",
        "IT": "Il mio 11 iniziale"
      }
    }, 
    {
      "title": "downloadButton", 
      "text": {
        "EN": "Download to share",
        "DE": "Herunterladen, um zu teilen",
        "FR": "Téléchargez pour partager",
        "ES": "Descárgalo para poder compartirlo",
        "AR": "قم بالتنزيل للمشاركة",
        "PT": "Baixe para compartilhar",
        "IT": "Scarica per condividere"
      }
    },
    {
      "title": "positionSelect", 
      "text":   {
        "EN": "Select your players position",
        "DE": "Deine Spielerposition wählen",
        "FR": "Sélectionnez le poste de vos joueurs",
        "ES": "Selecciona la demarcación de tus jugadores",
        "AR": "اختر مراكز لاعبيك",
        "PT": "Selecione a posição dos seus jogadores",
        "IT": "Seleziona la posizione dei tuoi giocatori"
      }
    },
    {
      "title": "playerSelect", 
      "text": {
        "EN": "Select your chosen player",
        "DE": "Deinen Spieler wählen",
        "FR": "Sélectionnez votre capitaine",
        "ES": "Selecciona al jugador elegido",
        "AR": "اختر لاعبك المفضل",
        "PT": "Selecione o seu jogador escolhido",
        "IT": "Seleziona il giocatore che hai scelto"
      }
    },
    {
      "title": "teamFill", 
      "text": {
        "EN": "Fill out your team",
        "DE": "Dein Team eingeben",
        "FR": "Constituez votre équipe",
        "ES": "Completa tu equipo",
        "AR": "اختر عناصر فريقك",
        "PT": "Monte a sua equipe",
        "IT": "Completa la tua squadra"
      }
    },
    {
      "title": "downloadText", 
      "text": {
        "EN": "Download and share with your friends",
        "DE": "Herunterladen und mit Freunden teilen",
        "FR": "Téléchargez et partagez avec vos amis",
        "ES": "Descárgalo y envíaselo a tus amigos",
        "AR": "قم بالتنزيل والمشاركة مع أصدقائك",
        "PT": "Baixe e compartilhe com os seus amigos",
        "IT": "Scarica e condividi con i tuoi amici"
      }
    },
    {
      "title": "teamSelect", 
      "text": {
        "EN": "Choose your team",
        "DE": "Dein Team wählen",
        "FR": "Choisissez votre équipe",
        "ES": "Elige tu equipo",
        "AR": "اختر فريقك",
        "PT": "Escolha a sua equipe",
        "IT": "Scegli la tua squadra"
      }
    },
    {
      "title": "deadlineHeader", 
      "text": {
        "EN": "Live predictions close 90 minutes before kick off",
        "DE": "Livetipps sind bis 90 Minuten vor Spielbeginn möglich.",
        "FR": "Les pronostics en direct sont clôturés 90 minutes avant le coup d’envoi d’un match",
        "ES": "Los pronósticos en directo cierran 90 minutos antes del pitido inicial",
        "AR": "يُغلق باب التوقعات المباشرة قبل 90 دقيقة من صافرة بداية المباراة",
        "PT": "Os palpites ao vivo fecham 90 minutos antes do início do jogo",
        "IT": "I pronostici live chiudono 90 minuti prima del calcio d'inizio"
      }
    },
    {
      "title": "formationChangeLabel", 
      "text": {
        "EN": "Change formation",
        "DE": "Aufstellung ändern",
        "FR": "Changer la formation",
        "ES": "Cambiar sistema de juego",
        "AR": "تغيير التشكيلة",
        "PT": "Alterar esquema tático",
        "IT": "Cambia formazione"
      }
    },
    {
      "title": "clearText", 
      "text": {
        "EN": "Clear selection",
        "DE": "Auswahl löschen",
        "FR": "Effacer la sélection",
        "ES": "Borrar selección",
        "AR": "مسح الاختيار",
        "PT": "Desfazer seleção",
        "IT": "Cancella selezione"
      }
    }
   ]