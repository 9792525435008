import styled from 'styled-components';
import Select from 'react-select';


export const FormationPage = styled.div`
  display: block;
  width: 100%;
`;

export const FormationDiv = styled.div`
  position: relative;
  display: block;
  width: min(95%, 26rem);
  margin: 1.5rem auto;

  p.note {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #ffffff;
    width: 100%;
    margin: 0.2rem 0 0.5rem;
    text-align: center;
    letter-spacing: 0.01rem;
    font-family: QatarBold;
  }
  .note:nth-child(2){
    color: #00CFB7;
  }
  .turq {
    color: #00CFB7 !important;
  }
  .white {
    color: #FFFFFF !important;
  }
`;

export const FieldSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  /* background: #004FBE; */
  height: 0;
  width: 100%;
  padding-top: calc(100% * calc(1359 / 827));

  img.fieldImg {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 60%;
  }
  
  .players {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
    /* padding-top: 0.75rem; */
    box-sizing: border-box;

    > img {
      width: 4rem;
      margin: 0.5rem 1rem -7rem 1rem;
    }
  }
`;

export const ButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  button {
    width: calc(50% - 0.5rem);
    border-radius: 0;
    border: 1px solid #550065;
    /* background: #00CFB7; */
    color: #550065;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
    font-family: PoppinsBold;
  }
`;

export const StyledSelect = styled(Select)`
  width: calc(50% - 0.5rem);
  border-radius: 0;
  border: 1px solid #550065;
  font-family: PoppinsBold;
  /* background: black; */

  .rs {
    &__control {
      cursor: pointer;
      border-radius: 0;
    }
    &__indicators {
      display: none;
    }
    &__value-container {
      padding: unset;
    }
    &__placeholder, &__single-value {
      width: 100%;
      text-align: center;
      color: #550065;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    &__menu {
      border-radius: 0;
      margin-bottom: 0;
      color: #550065;
      text-align: center;
      border: 1px solid #f1b2c8;
    }
    &__option {
      background: transparent !important;
      padding: 0.35rem 0;
      font-size: 0.8rem;
      cursor: pointer;

      &--is-selected, &--is-focused {
        color: black;
      }
    }
  }
`;

export const FormationRowDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const BannerDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  img{
    width: 100%;
  }
`;

export const PlayerFigure = styled.figure`
  position: relative;
  width: 4.5rem;
  margin: 0.5rem;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    margin-top: -1rem;
  }

  span {
    color: #fff;
    font-size: 0.8rem;
    /* background-color: rgba(0,0,0,0.5); */
    margin-top: 0.5rem;
    text-align: center;
    display: block;
    font-family: QatarRegular;
  }
`;

export const SaveButton = styled.button`
  position: relative;
  display: inline-block;
  background: #fff;
  color: #550065;
  border: none;
  border: 1px solid #550065;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  font-family: PoppinsBold;
  padding: 0.5rem;

  &:disabled {
    background: rgba(85, 0, 101, 0.1);
    cursor: default;
  }

  &:not(&:disabled) {
    &:hover {
      background: #cccccc;
    }
  }
`;
