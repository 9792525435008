import styled from 'styled-components';

export const SelectWrapperDiv = styled.div`
  position: fixed;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  top: 0;
  left: 0;
  width: 100%;
  max-width: 700px;
  height: 100vh;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 500;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(120deg, #550065, rgba(0,0,0,1));
`;

export const PlayerSection = styled.section`
  position: relative;
  display: block;
  background: ${({ index }) => index % 2 === 0 ? '#00CFB7' : '#00CFB7'};
  color: #550065;
  padding: 0.5rem;
  margin: 0.5rem 1rem;
  cursor: pointer;
  transform: translateX(${({ show }) => show ? '0%' : '110%'});
  transition: transform 0.15s ease-out ${({ index }) => 0.015 * index}s;

  &:last-child {
    margin-bottom: 4rem;
  }

  h2 {
    font-size: 1.2rem;
    font-family: QatarBold;
    margin: 0;
    line-height: 1rem;
  }

  p {
    font-size: 0.9rem;
    font-family: QatarLight;
    margin: 0;
  }

  img {
    position: absolute;
    width: ${({ hasImage }) => hasImage ? '3rem' : '2.75rem'};
    bottom: 0 ;
    margin: 0;
    right: 0.5rem;
  }
`;
