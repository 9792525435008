import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: PoppinsRegular;
    background: #550065;
    
    #screenshotIMG, #screenshotLink {
      position: fixed;
      z-index: 2000;
      top: 0;
      background-color: #fff;
    }
    /* canvas{
      width: 100%;
      position: fixed;
      top: 0;
    } */
  }

  /* brave browser weirdness */
  #blockColorblindContent {
    display: none;
  }

  #gradientBg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background-image: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0));
    opacity: 50%;
  }
`;

export const AppDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow-y: scroll;
  background: #550065;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40rem;
  max-width: 95vw;
  margin: 1rem 0;
  height: 100%;
`;
