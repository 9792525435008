import styled, { keyframes } from 'styled-components';

const mymove = keyframes`
    0% {
      transform: scale(1.0)
    }

    50% {
      transform: scale(1.25)
    }

    100% {
      transform: scale(1.0)
    }
  `;

export const ModalDiv = styled.div`
  position: fixed;
  display: flex;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: visibility 0s, opacity 0.2s linear;
  top: 0;
  width: 100%;
  height: 100vh;
  max-width: 700px;
  padding: 0;
  background: #550065;
  z-index: 40;

  section {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem auto;
    position: relative;
    font-family: QatarBold;
    color: white;
    padding-top: 5rem;

    h1 {
      font-size: 1.75rem;
      text-align: center;
      max-width: 90%;
      margin: 1rem auto;
    }

    h2 {
      color: #00CFB7;
      margin: 0;
      margin-top: 1rem;
    }

    h3 {
      margin: 0;
    }

    p {
      color: white;
      font-size: 1.25rem;
      font-family: QatarRegular;
      margin-bottom: 0.75rem;
      text-align: center;
    }
    .modal_body {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 11;
      text-align: center;
    }
  }
`;

export const TeamSelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    margin: 0;
    margin-bottom: 0.75rem;
    animation: ${mymove} 5s infinite;
    width: 6rem;
  }

  p {
    margin: 0.5rem 0;
  }

  div {
    margin: 0 2rem;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  position: fixed
  ;
  height: 100%;
  width: 100%;
  z-index: 9;
  margin: 0;
  top: 0;

  img {
    width: 7rem;
    position: fixed;
    margin: 1rem 1.5rem;
  }

  .logo {
    top: 0;
    left: 0;
  }

  .tr {
    top: 0;
    right: 0;
  }

  .bl {
    bottom: 0;
    left: 0;
  }

  .br {
    bottom: 0;
    right: 0;
  }
`;

