import styled from 'styled-components';

export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #004FBE;
  text-transform: uppercase;
  margin-top: 0.5rem;

  img:nth-child(1) {
    width: 45%
  }

  img:nth-child(2) {
    width: 30%;
    height: 100%;
  }

  img {
    /* height: 5rem; */
    margin: 0 1.5rem;
  }

  section {
    text-align: center;
    max-width: 70%;

    h1 {
      font-size: 1.8rem;
      color: #004FBE;
      margin: 0.3rem 0;
    }
    h3, p {
      margin: 0.1rem 0;
    }
    h3 {
      font-size: 1.1rem;
    }
    p {
      font-family: PoppinsRegular;
      font-size: 0.95rem;
    }
  }
`;
