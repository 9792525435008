import rfdc from 'rfdc';

const deepclone = rfdc();

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MATCH_DATA': {
      return {
        ...state,
        match: action.data
      }
    }

    case 'SET_SQUADS': {
      return {
        ...state,
        teams: action.squads
      }
    }

    case 'SET_MATCH': {
      return {
        ...state,
        date: action.date,
        stadium: action.stadium,
        stage: action.stage,
        text: action.text,
      }
    }

    case 'SET_TEAM': {
      return {
        ...state,
        selectedTeam: action.team,
        opponent: action.opponent,
        players: action.players
      }
    }

    case 'CLEAR_TEAM': {
      return {
        ...state,
        selectedTeam: {},
        opponent: {},
        players: [],
        showStartModal: true,
        showShareModal: false,
        activePosition: null,
        activeFormation: [4,4,2],
        formation: [
          [null],
          [null, null, null, null],
          [null, null, null, null],
          [null, null]
        ],
      }
    }

    case 'SET_SHOW_SHARE_MODAL': {
      return {
        ...state,
        showShareModal: action.show
      }
    }

    case 'SET_SHOW_START_MODAL': {
      return {
        ...state,
        showStartModal: action.show
      }
    }

    case 'SET_ACTIVE_POSITION': {
      return {
        ...state,
        activePosition: action.position
      }
    }

    case 'SET_PLAYER_TO_ACTIVE_POSITION': {

      const [col, row] = state.activePosition;
      const oldPlayer = deepclone(state.formation[col][row]);
      const newFormation = deepclone(state.formation);
      newFormation[col][row] = action.player;
      const playerIdx = state.players.findIndex(({ IdPlayer }) => IdPlayer === action.player.IdPlayer);
      const newPlayers = [
        ...state.players.slice(0, playerIdx),
        ...state.players.slice(playerIdx + 1),
      ];
      if (oldPlayer) newPlayers.push(oldPlayer);
      return {
        ...state,
        formation: newFormation,
        activePosition: null,
        players: newPlayers
      };
    }

    case 'FORMATION_CHANGE': {
      const newFormation = action.value.map(length => Array.from({ length }, () => null));
      newFormation.unshift([null]);
      return {
        ...state,
        formation: newFormation,
        activeFormation: action.value,
        activePosition: null,
        players: state.selectedTeam.Players,
      }
    }

    case 'CLEAR_SELECTION': {
      const newFormation = state.activeFormation.map(length => Array.from({ length }, () => null));
      newFormation.unshift([null]);
      return {
        ...state,
        formation: newFormation,
        activePosition: null,
        players: state.selectedTeam.Players,
      }
    }

    default:
      return state;
  }
}
